import React from 'react';
import {useAppContext} from '../use-app-state';
import {Routes} from '../router';
import Grid from '../components/grid';
import CloseCross from '../containers/close-cross';

const Tournament = () => {
  const {appState, actions} = useAppContext();

  const handleAddGroup = e => {
    actions.openRoute(Routes.NEW_GROUP);
  };

  const handleSelect = group => {
    actions.selectGroup(group);
    actions.openRoute(Routes.GROUP);
  };

  const tournament = appState.tournaments.find(t => t.id === appState.currentTournament);
  const _groups = appState.groups.filter(g => g.tournamentId === appState.currentTournament) || [];
  const groups = _groups.map(g =>
    g.archived
      ? {
          ...g,
          name: `${g.name} (archivé)`
        }
      : g
  );

  return (
    <>
      <h3>{tournament.name}</h3>
      <CloseCross route={Routes.TOURNAMENTS} />
      <Grid
        onSelect={handleSelect}
        onAdd={groups.length < 2 ? handleAddGroup : null}
        items={groups}
      />
    </>
  );
};

export default Tournament;
