import React, {useState} from 'react';
import {useAppContext} from '../use-app-state';
import {Routes} from '../router';
import generateUID from '../lib/generate-uid';
import CloseCross from '../containers/close-cross';
import ClickableBox from '../components/clickable-box';
import Players from '../components/players';

const NewTeam = () => {
  const {appState, actions} = useAppContext();
  const [state, setState] = useState({players: []});

  //----------------------------------------------------------------------------

  const handleCreate = e => {
    if (state.players.length < 2) {
      return;
    }

    actions.createTeam({
      id: generateUID('team'),
      groupId: appState.currentGroup,
      players: state.players.map(p => p.id)
    });

    actions.openRoute(Routes.GROUP);
  };

  const handleAddPlayer = player => {
    setState(state => ({players: [...state.players, player]}));
  };

  const handleRemovePlayer = player => {
    setState(state => ({players: state.players.filter(p => p.id !== player.id)}));
  };

  //----------------------------------------------------------------------------

  const group = appState.groups.find(g => g.id === appState.currentGroup);
  const groupTeams = appState.teams.filter(team => team.groupId === group.id);
  const groupPlayers = groupTeams.reduce((acc, team) => [...acc, ...team.players], []);

  const availablePlayers = appState.players.filter(
    p => !state.players.includes(p) && !groupPlayers.includes(p.id)
  );

  //----------------------------------------------------------------------------

  return (
    <>
      <CloseCross route={Routes.GROUP} />

      {availablePlayers.length > 0 ? (
        <h3>Créer une équipe</h3>
      ) : (
        <h3>Il faut rajouter des joueurs</h3>
      )}

      <Players onSelect={handleRemovePlayer} players={state.players} hideFilter warning />

      {state.players.length === 2 && (
        <ClickableBox
          onClick={handleCreate}
          text="Créer"
          style={{height: '60px', marginTop: '100px'}}
        />
      )}

      {state.players.length < 2 && (
        <div
          style={{width: '100%', marginTop: '100px', padding: '0 40px', boxSizing: 'border-box'}}
        >
          <Players onSelect={handleAddPlayer} players={availablePlayers} />
        </div>
      )}
    </>
  );
};

export default NewTeam;
