import React from 'react';
import {useAppContext} from '../use-app-state';
import {Routes} from '../router';
import Players from '../components/players';

const PlayersScreen = () => {
  const {appState, actions} = useAppContext();

  const handleAddPlayer = () => {
    actions.openRoute(Routes.NEW_PLAYER);
  };

  const players = appState.players;

  return (
    <>
      <h3>Joueurs</h3>
      <div style={{width: '100%', marginTop: '100px', padding: '0 40px', boxSizing: 'border-box'}}>
        <Players players={players} onAdd={handleAddPlayer} />
      </div>
    </>
  );
};

export default PlayersScreen;
