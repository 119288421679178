import React, {useState} from 'react';
import {useAppContext} from '../use-app-state';
import {Routes} from '../router';
import generateUID from '../lib/generate-uid';
import CloseCross from '../containers/close-cross';
import ClickableBox from '../components/clickable-box';
import Input from '../components/input';

const NewTournament = () => {
  const {actions} = useAppContext();
  const [state, setState] = useState({name: ''});

  const handleCreate = e => {
    if (state.name.length === 0) {
      return;
    }

    actions.createTournament({id: generateUID('tournament'), name: state.name, groups: []});
    actions.openRoute(Routes.TOURNAMENTS);
  };

  const handleNameChange = e => {
    setState({name: e.target.value});
  };

  return (
    <>
      <CloseCross route={Routes.TOURNAMENTS} />
      <h3>Créer un tournoi</h3>
      <Input onEnter={handleCreate} onChange={handleNameChange} value={state.name} />
      <ClickableBox
        onClick={handleCreate}
        text="Créer"
        style={{height: '60px', marginTop: '100px'}}
      />
    </>
  );
};

export default NewTournament;
