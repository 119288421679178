import React from 'react';
import {useAppContext} from '../use-app-state';
import ClickableBox from '../components/clickable-box';

const CloseCross = ({route}) => {
  const {actions} = useAppContext();

  const handleClick = e => {
    actions.openRoute(route);
  };

  return (
    <ClickableBox
      onClick={handleClick}
      text="x"
      style={{
        width: '40px',
        height: '40px',
        fontWeight: 800,
        fontSize: '30px',
        position: 'absolute',
        top: '20px',
        right: '30px',
        border: 'none',
        borderRadius: '50%',
        alignItems: 'unset'
      }}
    />
  );
};

export default CloseCross;
