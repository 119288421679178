import React from 'react';
import './app.css';
import {AppStateContext, useAppState} from './use-app-state';
import Router from './router';

const App = ({storageKey, initialState}) => {
  const {appState, actions} = useAppState(storageKey, initialState);

  return (
    <AppStateContext.Provider value={{appState, actions}}>
      <div className="App">
        <Router />
      </div>
    </AppStateContext.Provider>
  );
};

export default App;
