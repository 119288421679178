import React, {useState} from 'react';
import {useAppContext} from '../use-app-state';
import {Routes} from '../router';
import {newGroup} from '../lib/groups';
import CloseCross from '../containers/close-cross';
import ClickableBox from '../components/clickable-box';
import Input from '../components/input';

const NewGroup = () => {
  const {appState, actions} = useAppContext();
  const [state, setState] = useState({name: ''});

  const handleCreate = e => {
    if (state.name.length === 0) {
      return;
    }

    actions.createGroup(
      newGroup({
        name: state.name,
        tournamentId: appState.currentTournament
      })
    );
    actions.openRoute(Routes.TOURNAMENT);
  };

  const handleNameChange = e => {
    setState({name: e.currentTarget.value});
  };

  return (
    <>
      <CloseCross route={Routes.TOURNAMENT} />
      <h3>Créer un groupe</h3>
      <Input onEnter={handleCreate} onChange={handleNameChange} value={state.name} />
      <ClickableBox
        onClick={handleCreate}
        text="Créer"
        style={{height: '60px', marginTop: '100px'}}
      />
    </>
  );
};

export default NewGroup;
