import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import Routes from './router';
import * as serviceWorker from './serviceWorker';

console.log(`👨🏽‍🚀 Welcome to BadApp !`);

const STORAGE_KEY = 'badapp';
const previousAppState = localStorage.getItem(STORAGE_KEY);

console.log(`  ✅ previousAppState retrieved`);

const initialState = previousAppState
  ? JSON.parse(previousAppState)
  : {
      currentRoute: Routes.TOURNAMENTS,
      tournaments: [],
      groups: [],
      teams: [],
      games: [],
      players: []
    };

console.log(`  ✅ rendering App`);

ReactDOM.render(
  <App storageKey={STORAGE_KEY} initialState={initialState} />,
  document.getElementById('root')
);

console.log('  ✅ registering CRA service worker.');
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

console.log('👨🏽‍🚀 BadApp is ready.');
