import React, {useState} from 'react';
import Grid from './grid';
import Input from './input';

const Players = props => {
  const [state, setState] = useState({filter: ''});

  const handleAdd = e => {
    if (!props.onAdd) {
      return;
    }

    props.onAdd(e);
  };

  const handleSelect = item => {
    if (!props.onSelect) {
      return;
    }

    props.onSelect(item);
  };

  const handleChangeFilter = e => {
    setState({filter: e.target.value});
  };

  const players = props.players.filter(player =>
    player.name.toLowerCase().includes(state.filter.toLowerCase())
  );

  return (
    <>
      {!props.hideFilter && props.players.length > 0 && (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <span>Filtrer:</span>
          <Input
            style={{width: '250px', marginLeft: '20px'}}
            onChange={handleChangeFilter}
            value={state.name}
          />
        </div>
      )}
      <Grid
        warning={props.warning}
        onSelect={handleSelect}
        onAdd={props.onAdd ? handleAdd : null}
        items={players}
        style={{padding: '0 40px'}}
        itemStyle={{height: '80px'}}
      />
    </>
  );
};

export default Players;
