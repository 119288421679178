/**
 * from https://ricostacruz.com/til/state-management-with-react-hooks
 * The custom React hook to manage state
 */

import React, {useContext, useEffect, useState, useMemo} from 'react';
import {Routes} from './router';
import {generateGames} from './lib/games';
import {mixGroups} from './lib/groups';

//------------------------------------------------------------------------------

const AppStateContext = React.createContext({});

//------------------------------------------------------------------------------

const useAppState = (storageKey, initialState) => {
  const [appState, setAppState] = useState(initialState);

  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(appState));
  });

  const actions = useMemo(() => getActions(setAppState), [setAppState]);

  return {appState, actions};
};

//------------------------------------------------------------------------------
// tools: should be within /lib

//------------------------------------------------------------------------------

const getActions = setState => {
  const ACTIONS = {
    // Router -------------------------------------------------
    openRoute: (route, props) => {
      console.log(`👨🏽‍🚀 [openRoute] ${route}`);
      setState(state => ({...state, currentRoute: route}));

      if (route === Routes.TOURNAMENTS || route === Routes.PLAYERS) {
        setState(state => ({...state, currentTournament: undefined}));
      }

      if (route === Routes.TOURNAMENTS || route === Routes.TOURNAMENT || route === Routes.PLAYERS) {
        setState(state => ({...state, currentGroup: undefined}));
      }
    },

    // Tournaments --------------------------------------------
    createTournament: tournament => {
      console.log('👨🏽‍🚀 [createTournament]', tournament);
      setState(state => ({...state, tournaments: [...state.tournaments, tournament]}));
    },
    selectTournament: tournament => {
      console.log('👨🏽‍🚀 [selectTournament]', tournament);
      setState(state => ({...state, currentTournament: tournament.id}));
    },

    // Groups --------------------------------------------
    createGroup: group => {
      console.log('👨🏽‍🚀 [createGroup]', group);
      setState(state => ({...state, groups: [...state.groups, group]}));
    },
    selectGroup: group => {
      console.log('👨🏽‍🚀 [selectGroup]', group);
      setState(state => ({...state, currentGroup: group.id}));
    },
    deleteCurrentGroup: () => {
      console.log('👨🏽‍🚀 [deleteCurrentGroup]');
      setState(state => ({
        ...state,
        groups: state.groups.filter(g => g.id !== state.currentGroup)
      }));
      ACTIONS.openRoute(Routes.TOURNAMENT);
    },
    nextRound: ({teams, round: newRound, groupId, random}) => {
      console.log('👨🏽‍🚀 [nextRound]');
      const newGame = generateGames(teams, newRound, groupId, random);

      setState(state => ({
        ...state,
        games: [...state.games, ...newGame],
        groups: state.groups.map(group =>
          group.id === state.currentGroup ? {...group, currentRound: newRound} : group
        )
      }));
    },
    mixGroups: params => {
      console.log('👨🏽‍🚀 [mixGroups]');
      const {newG1, newG2, newTeamsG1, newTeamsG2} = mixGroups(params);
      ACTIONS.openRoute(Routes.TOURNAMENT);
      ACTIONS.createGroup(newG1);
      ACTIONS.selectGroup(newG1);
      newTeamsG1.forEach(ACTIONS.createTeam);

      ACTIONS.createGroup(newG2);
      ACTIONS.selectGroup(newG2);
      newTeamsG2.forEach(ACTIONS.createTeam);

      setState(state => ({
        ...state,
        groups: state.groups.map(group =>
          group.id === params.leaderboardG1[0].groupId ||
          group.id === params.leaderboardG2[0].groupId
            ? {...group, archived: true}
            : group
        )
      }));
    },

    // Team --------------------------------------------
    createTeam: team => {
      console.log('👨🏽‍🚀 [createTeam]', team);
      setState(state => ({...state, teams: [...state.teams, team]}));
    },
    deleteTeam: team => {
      console.log('👨🏽‍🚀 [deleteTeam]', team);
      setState(state => ({...state, teams: state.teams.filter(t => t.id !== team.id)}));
    },

    // Players --------------------------------------------
    createPlayer: player => {
      console.log('👨🏽‍🚀 [createPlayer]', player);
      setState(state => ({...state, players: [...state.players, player]}));
    },

    // Games --------------------------------------------
    selectGame: game => {
      console.log('👨🏽‍🚀 [selectGame]', game);
      setState(state => ({...state, currentGame: game.id}));
    },
    setGameCompleted: value => {
      console.log('👨🏽‍🚀 [setGameCompleted] ', value);
      setState(state => ({
        ...state,
        games: state.games.map(game =>
          game.id === state.currentGame ? {...game, completed: value} : game
        )
      }));
    },
    addSet: set => {
      console.log('👨🏽‍🚀 [addSet]', set);
      setState(state => ({
        ...state,
        games: state.games.map(game =>
          game.id === state.currentGame ? {...game, sets: [...game.sets, set]} : game
        )
      }));
    },
    deleteSet: index => {
      console.log('👨🏽‍🚀 [deleteSet]', index);
      setState(state => ({
        ...state,
        games: state.games.map(game =>
          game.id === state.currentGame
            ? {...game, sets: game.sets.filter((set, i) => index !== i)}
            : game
        )
      }));
    }
  };

  return ACTIONS;
};

//------------------------------------------------------------------------------

// Sub-components can use this function. It will pick up the
// `state` and `actions` given by useAppState() higher in the
// component tree.
const useAppContext = () => {
  return useContext(AppStateContext);
};

//------------------------------------------------------------------------------

export {AppStateContext, useAppState, useAppContext};
