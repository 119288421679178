import React from 'react';
import './clickable-box.css';

const ClickableBox = props => {
  const handleClick = e => {
    if (!props.onClick) {
      return;
    }

    props.onClick(e);
  };

  return (
    <div
      className={`clickable-box ${props.warning ? 'warning' : ''}`}
      onClick={handleClick}
      style={props.style}
    >
      {props.text}
    </div>
  );
};

export default ClickableBox;
