import React from 'react';
import './grid.css';
import ClickableBox from './clickable-box';

const Grid = props => {
  const handleSelect = item => e => {
    props.onSelect(item);
  };

  const handleAdd = e => {
    props.onAdd();
  };

  return (
    <ul className="grid" style={props.style}>
      {props.onAdd !== null && (
        <li onClick={handleAdd} key="add-item">
          <ClickableBox text="+" style={{fontWeight: 800, fontSize: '40px', ...props.itemStyle}} />
        </li>
      )}

      {props.items.map(item => (
        <li onClick={handleSelect(item)} key={item.id}>
          <ClickableBox warning={props.warning} text={item.name} style={props.itemStyle} />
        </li>
      ))}
    </ul>
  );
};

export default Grid;
