import React from 'react';
import {useAppContext} from '../use-app-state';
import {Routes} from '../router';
import Grid from '../components/grid';

const Tournaments = () => {
  const {appState, actions} = useAppContext();

  const handleAdd = e => {
    actions.openRoute(Routes.NEW_TOURNAMENT);
  };

  const handleSelect = tournament => {
    actions.selectTournament(tournament);
    actions.openRoute(Routes.TOURNAMENT);
  };

  return (
    <>
      <h3>Tournois</h3>
      <Grid onSelect={handleSelect} onAdd={handleAdd} items={appState.tournaments} />
    </>
  );
};

export default Tournaments;
