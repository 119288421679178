import React from 'react';

const Input = props => {
  const handleKeyPressed = e => {
    if (!props.onEnter) {
      return;
    }

    if (e.key === 'Enter') {
      props.onEnter(e);
    }
  };

  const handleChange = e => {
    if (!props.onChange) {
      return;
    }

    props.onChange(e);
  };

  return (
    <input
      onKeyPress={handleKeyPressed}
      onChange={handleChange}
      value={props.value}
      style={{height: '40px', width: '350px', fontSize: '35px', ...props.style}}
    />
  );
};

export default Input;
