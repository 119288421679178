export const getGroup = (appState, groupId) => appState.groups.find(g => g.id === groupId);
export const getCurrentGroup = appState => getGroup(appState, appState.currentGroup);

export const getCurrentGame = appState => appState.games.find(g => g.id === appState.currentGame);

export const getTeam = (appState, teamId) => appState.teams.find(team => teamId === team.id);

export const getGroupTeams = (appState, group) =>
  appState.teams.filter(team => team.groupId === group.id);

// all games for a group, at currentRound
export const getCurrentGames = (appState, group) =>
  group.currentRound === 0
    ? []
    : appState.games.filter(game => game.groupId === group.id && game.round === group.currentRound);

// all games for a team
export const getTeamGames = (appState, team) =>
  appState.games.filter(
    game => game.groupId === team.groupId && game.teams.find(t => t.id === team.id)
  );

export const isCurrentRoundCompleted = (appState, group) => {
  const currentGames = getCurrentGames(appState, group);
  const roundCompleted = currentGames.reduce(
    (othersAreCompleted, game) => game.completed && othersAreCompleted,
    true
  );

  const justMixed = group.mixed && group.currentRound === 0;

  return roundCompleted && !justMixed;
};
