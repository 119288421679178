import React from 'react';
import {useAppContext} from '../use-app-state';
import ClickableBox from '../components/clickable-box';

const Team = props => {
  const {appState} = useAppContext();
  const {playerIds, onClick} = props;

  const players = appState.players.filter(player => playerIds.includes(player.id));
  const names = players.map(p => <span key={p.id}>{p.name}</span>);

  return (
    <ClickableBox
      warning={props.warning}
      text={names}
      onClick={onClick}
      style={{
        height: '60px',
        width: '250px',
        fontSize: 'calc(5px + 1.5vmin)',
        flexDirection: 'column'
      }}
    />
  );
};

export default Team;
