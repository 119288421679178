import React, {useState} from 'react';
import {useAppContext} from '../use-app-state';
import {Routes} from '../router';
import CloseCross from '../containers/close-cross';
import Game from '../containers/game';
import ClickableBox from '../components/clickable-box';
import Input from '../components/input';
import {getCurrentGame, getGroup} from '../lib/app-state-extractor';
import './game-details.css';

// -----------------------------------------------------------------------------

const isNumeric = s => s.length > 0 && !isNaN(s);

const validateScore = score => {
  if (score.length === 0) {
    return 0;
  }

  if (!isNumeric(score)) {
    return null;
  }

  const num = Number.parseInt(score);
  if (num < 0) {
    return null;
  }

  return num;
};

// -----------------------------------------------------------------------------

const Sets = ({sets, handleDeleteSet}) => {
  if (sets.length === 0) {
    return <span>pas encore de sets</span>;
  }

  return (
    <div className="sets">
      {sets.map((set, index) => (
        <div className="set" key={`set-${index}`}>
          <span
            className="score"
            style={{
              fontWeight: set[0].score > set[1].score && 'bold',
              fontSize: set[0].score > set[1].score && '24px'
            }}
          >
            {set[0].score}
          </span>
          <span style={{width: '60px'}}> - </span>
          <span
            className="score"
            style={{
              fontWeight: set[1].score > set[0].score && 'bold',
              fontSize: set[1].score > set[0].score && '24px'
            }}
          >
            {set[1].score}
          </span>
          <span onClick={handleDeleteSet(index)} className="cross-delete">
            x
          </span>
        </div>
      ))}
    </div>
  );
};
// -----------------------------------------------------------------------------

const AddSet = props => (
  <div
    style={{
      width: '400px',
      height: '100px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}
  >
    <span>Ajouter un set:</span>
    <Input
      onChange={props.handleScore1Change}
      value={props.score1}
      style={{width: '50px', textAlign: 'center'}}
    />
    <Input
      onChange={props.handleScore2Change}
      value={props.score2}
      style={{width: '50px', textAlign: 'center'}}
    />
    <ClickableBox
      onClick={props.handleAddSet}
      text="+"
      style={{
        width: '45px',
        height: '45px',
        fontWeight: 'bold',
        fontSize: '27px'
      }}
    />
  </div>
);

// -----------------------------------------------------------------------------

const GameDetails = () => {
  const {appState, actions} = useAppContext();
  const [state, setState] = useState({score1: 0, score2: 0});
  const game = getCurrentGame(appState);
  const group = getGroup(appState, game.groupId);

  //----------------------------------------------

  const handleScore1Change = e => {
    const score1 = validateScore(e.target.value);
    if (score1 !== null) {
      setState(s => ({...s, score1}));
    }
  };

  const handleScore2Change = e => {
    const score2 = validateScore(e.target.value);
    if (score2 !== null) {
      setState(s => ({...s, score2}));
    }
  };

  //----------------------------------------------

  const handleSetCompleted = () => {
    actions.setGameCompleted(true);
  };

  const handleSetNotCompleted = () => {
    actions.setGameCompleted(false);
  };

  const handleAddSet = () => {
    actions.addSet([
      {
        team: game.teams[0],
        score: state.score1
      },
      {
        team: game.teams[1],
        score: state.score2
      }
    ]);

    setState({score1: 0, score2: 0});
  };

  const handleDeleteSet = index => e => {
    if (game.completed || group.archived) {
      return;
    }

    actions.deleteSet(index);
  };

  //----------------------------------------------

  return (
    <>
      <CloseCross route={Routes.GROUP} />
      <h1>Match</h1>
      <Game game={game} />
      <Sets sets={game.sets} handleDeleteSet={handleDeleteSet} />

      {!game.completed && (
        <>
          <AddSet
            score1={state.score1}
            score2={state.score2}
            handleAddSet={handleAddSet}
            handleScore1Change={handleScore1Change}
            handleScore2Change={handleScore2Change}
          />

          <ClickableBox
            onClick={handleSetCompleted}
            text="Cloturer le match"
            style={{height: '60px', marginTop: '100px'}}
          />
        </>
      )}

      {game.completed && !group.archived && (
        <ClickableBox
          onClick={handleSetNotCompleted}
          text="Editer le match"
          style={{height: '60px', marginTop: '100px'}}
        />
      )}
    </>
  );
};

export default GameDetails;
