import React from 'react';
import Team from '../containers/team';

/*
  games: Array []
  groupId: "group-1579560670682"
  id: "team-1579560675085"
  nbSetsLost: 0
  nbSetsWon: 0
  opponents: Array []
  opponentsDiffPoints: 0
  opponentsDiffSets: 0
  opponentsNbSetsWon: 0
  players: Array [ "player-1579560499095", "player-1579560500840" ]
  totalDiffPoints: 0
  victories: 0
*/
const Leaderboard = ({leaderboard}) => (
  <>
    <div className="leaderboard-line">
      <span className="points" style={{paddingLeft: '330px'}} title="victoires">
        V
      </span>
      <span className="points" title="sets">
        S
      </span>
      <span className="points" title="sets adversaires">
        SA
      </span>
      <span className="points" title="goal average">
        G
      </span>
      <span className="points" title="goal average adversaires">
        GA
      </span>
    </div>

    {leaderboard.map((team, index) => (
      <div key={team.id} className="leaderboard-line">
        <span className="rank">{index + 1}</span>
        <Team playerIds={team.players} />
        <span className="points">{team.victories}</span>
        <span className="points">{team.nbSetsWon - team.nbSetsLost}</span>
        <span className="points">{team.opponentsDiffSets}</span>
        <span className="points">{team.totalDiffPoints}</span>
        <span className="points">{team.opponentsDiffPoints}</span>
      </div>
    ))}
  </>
);

export default Leaderboard;
