import React, {useEffect} from 'react';
import {useAppContext} from '../use-app-state';
import {Routes} from '../router';
import CloseCross from '../containers/close-cross';
import ClickableBox from '../components/clickable-box';
import Leaderboard from '../components/leaderboard';
import './group.css';
import Game from '../containers/game';
import Team from '../containers/team';
import {
  getCurrentGames,
  getCurrentGroup,
  getGroupTeams,
  isCurrentRoundCompleted
} from '../lib/app-state-extractor';
import generateLeaderboard from '../lib/leaderboard';

// -----------------------------------------------------------------------------

const MINIMUN_TEAMS_REQUIRED = 4;

const Registering = ({addTeam, deleteGroup, deleteTeam, teams, startGroupPlaying}) => {
  const enoughTeams = teams.length >= MINIMUN_TEAMS_REQUIRED;
  const isEven = teams.length % 2 === 0;

  return (
    <>
      <h3>Inscription</h3>
      <h4>Equipes:</h4>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        {teams.map(team => (
          <Team
            key={`registered-${team.id}`}
            playerIds={team.players}
            warning
            onClick={deleteTeam(team)}
          />
        ))}
      </div>

      {!enoughTeams && <p>Il faut au moins {MINIMUN_TEAMS_REQUIRED} équipes.</p>}
      {enoughTeams && !isEven && <p>Il faut une nombre pair d'équipes.</p>}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ClickableBox
          onClick={addTeam}
          text="Ajouter une équipe"
          style={{height: '80px', marginTop: '100px'}}
        />
        <ClickableBox
          warning
          onClick={deleteGroup}
          text="Supprimer ce groupe"
          style={{height: '80px', marginTop: '100px'}}
        />
        {enoughTeams && isEven && (
          <ClickableBox
            onClick={startGroupPlaying}
            text="Groupe prêt"
            style={{height: '80px', marginTop: '100px'}}
          />
        )}
      </div>
    </>
  );
};

// -----------------------------------------------------------------------------

const NextActions = ({nextRound, mixGroups, group, appState, tournamentGroups}) => {
  const otherGroup = tournamentGroups.find(g => g.id !== group.id);
  const otherGroupReady =
    otherGroup &&
    isCurrentRoundCompleted(appState, otherGroup) &&
    otherGroup.currentRound === group.currentRound;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '75px'
      }}
    >
      <ClickableBox onClick={nextRound} text="Lancer le tour suivant" style={{height: '80px'}} />
      {otherGroup &&
        (otherGroupReady ? (
          <ClickableBox
            onClick={mixGroups}
            text={`Mélanger ${tournamentGroups.map(g => g.name)}`}
            style={{height: '80px'}}
          />
        ) : (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '30px'
            }}
          >
            <p style={{margin: 0, padding: 0, fontSize: '18px'}}>
              Mélange des groupes non possible:
            </p>
            <p style={{margin: 0, padding: 0, fontSize: '15px'}}>
              Il faut que les groupes soient au meme tour ({otherGroup.name} est au tour{' '}
              {otherGroup.currentRound})
            </p>
          </div>
        ))}
    </div>
  );
};

// -----------------------------------------------------------------------------

const Games = ({games, round}) => (
  <>
    <h3>Matchs du tour {round}</h3>
    {games.map(game => (
      <div key={game.id}>
        <Game game={game} />
      </div>
    ))}
  </>
);

// -----------------------------------------------------------------------------

const Group = () => {
  const {appState, actions} = useAppContext();
  const group = getCurrentGroup(appState);
  const teams = getGroupTeams(appState, group);
  const currentGames = getCurrentGames(appState, group);
  const roundCompleted = isCurrentRoundCompleted(appState, group);

  const tournamentGroups =
    appState.groups.filter(g => g.tournamentId === appState.currentTournament && !g.archived) || [];

  // -------------------------------------------

  const leaderboard = generateLeaderboard({
    group: group.id,
    allGames: appState.games,
    teams,
    round: group.currentRound
  });

  // -------------------------------------------

  const addTeam = () => {
    actions.openRoute(Routes.NEW_TEAM);
  };

  const deleteTeam = team => e => {
    actions.deleteTeam(team);
  };

  const deleteCurrentGroup = () => {
    actions.deleteCurrentGroup();
  };

  const startGroupPlaying = () => {
    actions.nextRound({
      teams: leaderboard,
      round: 1,
      groupId: group.id,
      random: true
    });
  };

  const nextRound = () => {
    actions.nextRound({
      teams: leaderboard,
      round: group.currentRound + 1,
      groupId: group.id
    });
  };

  const mixGroups = () => {
    const leaderboardG1 = leaderboard;
    const G2 = tournamentGroups.find(g => g.id !== group.id);
    const teamsG2 = getGroupTeams(appState, G2);

    const leaderboardG2 = generateLeaderboard({
      group: G2.id,
      allGames: appState.games,
      teams: teamsG2,
      round: G2.currentRound
    });

    actions.mixGroups({
      tournamentId: appState.currentTournament,
      nameG1: `${group.name}[*]`,
      nameG2: `${G2.name}[*]`,
      leaderboardG1,
      leaderboardG2
    });
  };

  // -------------------------------------------

  useEffect(() => {
    if (group.currentRound === 0 && group.mixed) {
      startGroupPlaying();
    }
  });

  // -------------------------------------------

  return (
    <>
      <CloseCross route={Routes.TOURNAMENT} />
      <h2>{`${group.name} ${group.archived ? '(Archivé)' : ''}`}</h2>
      {group.currentRound > 0 && roundCompleted && !group.archived && (
        <NextActions
          nextRound={nextRound}
          mixGroups={mixGroups}
          group={group}
          appState={appState}
          tournamentGroups={tournamentGroups}
        />
      )}

      {group.currentRound > 0 && (
        <div className="leaderboard">
          <h3>Tour {group.currentRound}</h3>
          <h3>{group.currentRound > 0 ? '' : 'Equipes'}</h3>
          <Leaderboard leaderboard={leaderboard} />
        </div>
      )}

      <div className="games">
        {group.currentRound === 0 && (
          <Registering
            addTeam={addTeam}
            deleteGroup={deleteCurrentGroup}
            deleteTeam={deleteTeam}
            startGroupPlaying={startGroupPlaying}
            teams={teams}
          />
        )}
        {group.currentRound > 0 && <Games games={currentGames} round={group.currentRound} />}
      </div>
    </>
  );
};

export default Group;
