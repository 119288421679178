import React from 'react';
import {useAppContext} from '../use-app-state';
import Team from './team';
import {Routes} from '../router';
import {getTeam} from '../lib/app-state-extractor';
import {getGameScore} from '../lib/games';

const Game = props => {
  const {appState, actions} = useAppContext();
  const {game} = props;

  const leftTeam = getTeam(appState, game.teams[0]);
  const rightTeam = getTeam(appState, game.teams[1]);

  console.log({game});

  const openGame = e => {
    actions.selectGame(game);
    actions.openRoute(Routes.GAME_DETAILS);
  };

  const gameScore = getGameScore(game.sets);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative'
      }}
      onClick={openGame}
    >
      {game.completed && (
        <span
          style={{
            backgroundColor: '#2fa929',
            padding: '10px',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            left: gameScore[0].victories > gameScore[1].victories ? '-20px' : '620px'
          }}
        >
          ✔
        </span>
      )}
      {leftTeam && <Team playerIds={leftTeam.players} />}
      <div className="set" style={{width: '120px', cursor: 'pointer'}}>
        <span style={{marginLeft: '20px', fontSize: '40px'}}>{gameScore[0].victories}</span>
        <span style={{width: '30px'}}>-</span>
        <span style={{marginRight: '20px', fontSize: '40px'}}>{gameScore[1].victories}</span>
      </div>
      {rightTeam && <Team playerIds={rightTeam.players} />}
    </div>
  );
};

export default Game;
