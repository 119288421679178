import {getGameScore} from './games';

//------------------------------------------------------------------------------

const aggregateRound = ({group, allGames, round, currentAggregation}) => {
  console.log(`👨🏽‍🚀 aggregating [${group}, round: ${round}]`);

  const games = allGames.filter(
    game => game.groupId === group && game.round === round && game.completed === true
  );

  const teamIds = Object.keys(currentAggregation);

  const newAggregation = teamIds.reduce((acc, team) => {
    const aggregation = currentAggregation[team];
    const game = games.find(game => game.teams.includes(team));

    if (!game) {
      return acc;
    }

    const score = getGameScore(game.sets);
    const teamScore = score.find(s => s.team === team);
    const opponentScore = score.find(s => s.team !== team);
    const opponentTeam = game.teams.find(t => t !== team);

    return {
      ...acc,
      [team]: {
        victories: aggregation.victories + (teamScore.victories > opponentScore.victories ? 1 : 0),
        nbSetsWon: aggregation.nbSetsWon + teamScore.victories,
        nbSetsLost: aggregation.nbSetsLost + opponentScore.victories,
        opponents: [...aggregation.opponents, opponentTeam],
        totalDiffPoints: aggregation.totalDiffPoints + teamScore.diff
      }
    };
  }, currentAggregation);

  if (round <= 1) {
    return newAggregation;
  }

  return aggregateRound({group, allGames, round: round - 1, currentAggregation: newAggregation});
};

//------------------------------------------------------------------------------

const plugAggregation = (teams, aggregation) => {
  const opponentSetCounter = (opponentnbSetsWon, opponent) =>
    opponentnbSetsWon + aggregation[opponent].nbSetsWon;

  const opponentDiffPointsCounter = (opponentDiffPoints, opponent) =>
    opponentDiffPoints + aggregation[opponent].totalDiffPoints;

  const opponentDiffSetsCounter = (opponentDiffSets, opponent) =>
    opponentDiffSets + aggregation[opponent].nbSetsWon - aggregation[opponent].nbSetsLost;

  const extension = team =>
    aggregation[team.id]
      ? {
          ...aggregation[team.id],
          opponentsNbSetsWon: aggregation[team.id].opponents.reduce(opponentSetCounter, 0),
          opponentsDiffPoints: aggregation[team.id].opponents.reduce(opponentDiffPointsCounter, 0),
          opponentsDiffSets: aggregation[team.id].opponents.reduce(opponentDiffSetsCounter, 0)
        }
      : {};

  return teams.reduce(
    (acc, team) => [
      ...acc,
      {
        ...team,
        ...extension(team)
      }
    ],
    []
  );
};

//------------------------------------------------------------------------------

const generateLeaderboard = ({group, allGames, teams, round}) => {
  console.log('--------------------------------------------------------');
  console.log(`👨🏽‍🚀 generate leaderboard for [${group}, round: ${round}]`);

  console.log({teams});

  // --------------------------------------------------------

  const startupAggregation = teams.reduce(
    (acc, team) => ({
      ...acc,
      [team.id]: {
        victories: 0,
        nbSetsWon: 0,
        nbSetsLost: 0,
        opponents: [],
        totalDiffPoints: 0
      }
    }),
    {}
  );

  // --------------------------------------------------------

  const aggregation = aggregateRound({
    group,
    allGames,
    round,
    currentAggregation: startupAggregation
  });

  console.log(`👨🏽‍🚀 aggregation`, aggregation);

  // --------------------------------------------------------

  const teamsWithPoints = plugAggregation(teams, aggregation);

  teamsWithPoints.sort((team1, team2) => {
    // - 💪 just stronger -------------------------------
    if (team2.victories !== team1.victories) {
      return team2.victories - team1.victories;
    }

    // - if same victories ------------------------------
    if (team2.nbSetsWon - team2.nbSetsLost !== team1.nbSetsWon - team1.nbSetsLost) {
      return team2.nbSetsWon - team2.nbSetsLost - (team1.nbSetsWon - team1.nbSetsLost);
    }

    // - if same nbSetsWon ---------------------------------

    if (team2.opponentsDiffSets !== team1.opponentsDiffSets) {
      return team2.opponentsDiffSets - team1.opponentsDiffSets;
    }

    // - if same opponent nbSetsWon ------------------------
    if (team2.totalDiffPoints !== team1.totalDiffPoints) {
      return team2.totalDiffPoints - team1.totalDiffPoints;
    }

    // - if same diff points ----------------------------

    if (team2.opponentsDiffPoints !== team1.opponentsDiffPoints) {
      return team2.opponentsDiffPoints - team1.opponentsDiffPoints;
    }

    // 🖕equality !
    return 0;
  });

  console.log(`👨🏽‍🚀 leaderboard`, teamsWithPoints);
  console.log('--------------------------------------------------------');
  return teamsWithPoints;
};

//------------------------------------------------------------------------------

export default generateLeaderboard;
