import React from 'react';
import './app.css';

import {useAppContext} from './use-app-state';
import GameDetails from './screens/game-details';
import Group from './screens/group';
import NewGroup from './screens/new-group';
import NewPlayer from './screens/new-player';
import NewTeam from './screens/new-team';
import NewTournament from './screens/new-tournament';
import Tournament from './screens/tournament';
import Players from './screens/players';
import Tournaments from './screens/tournaments';

import generateUID from './lib/generate-uid';

const currentScreen = route => {
  switch (route) {
    case Routes.NEW_GROUP:
      return NewGroup;
    case Routes.NEW_PLAYER:
      return NewPlayer;
    case Routes.NEW_TEAM:
      return NewTeam;
    case Routes.NEW_TOURNAMENT:
      return NewTournament;
    case Routes.GROUP:
      return Group;
    case Routes.GAME_DETAILS:
      return GameDetails;
    case Routes.PLAYERS:
      return Players;
    case Routes.TOURNAMENTS:
      return Tournaments;
    case Routes.TOURNAMENT:
      return Tournament;

    default:
      return Tournaments;
  }
};

function Router() {
  const {appState, actions} = useAppContext();
  const Screen = currentScreen(appState.currentRoute);

  const openTournaments = e => {
    actions.openRoute(Routes.TOURNAMENTS);
  };

  const openPlayers = e => {
    actions.openRoute(Routes.PLAYERS);
  };

  const handleSave = e => {
    const jsonData = localStorage.getItem('badapp');
    window.download(jsonData, `${generateUID('state')}.json`, 'application/json');
  };

  const currentTournamentName = appState.currentTournament ? (
    <i>{appState.tournaments.find(t => t.id === appState.currentTournament).name} ></i>
  ) : null;

  const currentGroupName = appState.currentGroup ? (
    <i>{appState.groups.find(t => t.id === appState.currentGroup).name} ></i>
  ) : null;

  return (
    <>
      <div className="header">
        <span onClick={openTournaments}>Tournois</span>
        <span onClick={openPlayers}>Joueurs</span>
        {currentTournamentName}
        {currentGroupName}
        <i>{appState.currentRoute}</i>

        <span style={{marginLeft: '200px'}} onClick={handleSave}>
          save state
        </span>

        <h5 style={{marginLeft: '30px'}}>v1.1</h5>
      </div>
      <div className="screen">
        <Screen />
      </div>
    </>
  );
}

export const Routes = {
  TOURNAMENT: '/tournament',
  TOURNAMENTS: '/tournaments',
  GAME_DETAILS: '/game-details',
  GROUP: '/group',
  NEW_GROUP: '/new-group',
  NEW_TEAM: '/new-team',
  NEW_TOURNAMENT: '/new-tournament',
  NEW_PLAYER: '/new-player',
  PLAYERS: '/players'
};

export default Router;
