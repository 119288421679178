import generateUID from './generate-uid';

//------------------------------------------------------------------------------

const defaultMatchup = leaderboard => {
  console.log(
    '\n================================================================= defaultMatchup\n'
  );
  const duels = [];
  const teams = [...leaderboard];

  while (teams.length > 0) {
    const team = teams.shift();
    console.log('------------------');
    console.log('picked', team.id);
    console.log('   remaining teams: ', JSON.stringify(teams.map(t => t.id)));

    let opponentTeam = teams.find(opponentTeam => !team.opponents.includes(opponentTeam.id));
    if (!opponentTeam) {
      console.log('---> opponent not met before, choosing first one');
      opponentTeam = teams.shift();
    } else {
      const index = teams.indexOf(opponentTeam);
      teams.splice(index, 1);
    }

    console.log('opponentTeam', opponentTeam.id);
    console.log('   remaining teams after duel: ', JSON.stringify(teams.map(t => t.id)));
    duels.push([team.id, opponentTeam.id]);
  }

  return duels;
};

//------------------------------------------------------------------------------

const generateDuels = leaderboard => {
  let teams = [...leaderboard];
  const tries = teams.reduce((acc, team) => ({...acc, [team.id]: 0}), {});
  const duels = [];

  while (teams.length > 0) {
    console.log('------');
    const team = teams.shift();
    console.log('looking for an opponent for', team.id);
    console.log('remaining teams: ', JSON.stringify(teams.map(t => t.id)));

    const freeOpponents = teams.filter(t => !team.opponents.includes(t.id));
    console.log('freeOpponents: ', JSON.stringify(freeOpponents.map(t => t.id)));

    if (freeOpponents.length === 0) {
      console.log('CANCELLING --> no free opponents', team.id);
      const lastDuel = duels.pop();
      const currentCursor = tries[lastDuel[0]];

      tries[lastDuel[0]] = tries[lastDuel[0]] + 1;
      teams = [...leaderboard].filter(t => !duels.flat().find(id => id === t.id));

      // reset deep tries because restarting from upper cursor
      // eslint-disable-next-line no-loop-func
      teams.forEach(t => (tries[t.id] = 0));
      tries[lastDuel[0]] = currentCursor + 1;

      console.log('tries :', tries);
      console.log('teams: ', JSON.stringify(teams.map(t => t.id)));
      console.log('--------------------');
      continue;
    }

    let nextFreeOpponent = freeOpponents[tries[team.id]];

    if (!nextFreeOpponent) {
      console.log('CANCELLING --> no nextFreeOpponent', team.id);
      const lastDuel = duels.pop();

      if (!lastDuel) {
        console.log('FALLBACK --> could not find a matchup with all new duels');
        return defaultMatchup(leaderboard);
      }

      const currentCursor = tries[lastDuel[0]];

      tries[lastDuel[0]] = tries[lastDuel[0]] + 1;
      teams = [...leaderboard].filter(t => !duels.flat().find(id => id === t.id));

      // reset deep tries because restarting from upper cursor
      // eslint-disable-next-line no-loop-func
      teams.forEach(t => (tries[t.id] = 0));
      tries[lastDuel[0]] = currentCursor + 1;

      console.log('tries :', tries);
      console.log('teams: ', JSON.stringify(teams.map(t => t.id)));
      console.log('--------------------');
      continue;
    }

    console.log('nextFreeOpponent', JSON.stringify(nextFreeOpponent.id));
    const duel = [team.id, nextFreeOpponent.id];
    const index = teams.indexOf(nextFreeOpponent);
    teams.splice(index, 1);

    console.log('adding duel', JSON.stringify(duel));
    duels.push(duel);
  }

  return duels;
};

//------------------------------------------------------------------------------

const random = (start, end) => Math.floor(Math.random() * (end - start + 1)) + start;

//------------------------------------------------------------------------------

const randomizeDuels = leaderboard => {
  const duels = [];
  const teams = [...leaderboard];

  while (teams.length > 0) {
    const team = teams.shift();
    const index = random(0, teams.length - 1);
    const opponentTeam = teams[index];
    teams.splice(index, 1);
    duels.push([team.id, opponentTeam.id]);
  }

  return duels;
};

//------------------------------------------------------------------------------

const generateGames = (leaderboard, round, groupId, random) => {
  const duels = random ? randomizeDuels(leaderboard) : generateDuels(leaderboard);

  return duels.map((teams, index) => ({
    id: generateUID(`game-${index}`),
    teams,
    sets: [],
    groupId,
    round,
    completed: false
  }));
};

//------------------------------------------------------------------------------

const getGameScore = sets => {
  if (sets.length === 0) {
    return [0, 0];
  }

  return sets.reduce(
    (scores, set) => {
      const currentScores = [
        scores.find(score => score.team === set[0].team),
        scores.find(score => score.team === set[1].team)
      ];

      return [
        {
          team: set[0].team,
          victories: currentScores[0].victories + (set[0].score > set[1].score ? 1 : 0),
          diff: currentScores[0].diff + (set[0].score - set[1].score)
        },
        {
          team: set[1].team,
          victories: currentScores[1].victories + (set[1].score > set[0].score ? 1 : 0),
          diff: currentScores[1].diff + (set[1].score - set[0].score)
        }
      ];
    },
    [
      {
        team: sets[0][0].team,
        victories: 0,
        diff: 0
      },
      {
        team: sets[0][1].team,
        victories: 0,
        diff: 0
      }
    ]
  );
};

//------------------------------------------------------------------------------

export {generateGames, getGameScore};
