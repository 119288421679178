import generateUID from './generate-uid';

// -----------------------------------------------------------------------------

const newGroup = ({tournamentId, name, mixed = false}) => ({
  id: generateUID(`group-${name}`),
  tournamentId,
  name,
  mixed,
  currentRound: 0
});

// -----------------------------------------------------------------------------

const mixGroups = ({tournamentId, nameG1, nameG2, leaderboardG1, leaderboardG2}) => {
  const G1 = [...leaderboardG1];
  const G2 = [...leaderboardG2];

  const topG1 = G1.splice(0, G1.length / 2);
  const topG2 = G2.splice(0, G2.length / 2);

  const selectedTeamsG1 = [...topG1, ...topG2];
  const selectedTeamsG2 = [...G1, ...G2];

  const newG1 = newGroup({
    tournamentId,
    name: nameG1,
    mixed: true
  });

  const newG2 = newGroup({
    tournamentId,
    name: nameG2,
    mixed: true
  });

  const newTeamsG1 = selectedTeamsG1.map((team, index) => ({
    id: generateUID(`team1${index}`),
    groupId: newG1.id,
    players: team.players
  }));

  const newTeamsG2 = selectedTeamsG2.map((team, index) => ({
    id: generateUID(`team2${index}`),
    groupId: newG2.id,
    players: team.players
  }));

  return {newG1, newG2, newTeamsG1, newTeamsG2};
};

export {newGroup, mixGroups};
